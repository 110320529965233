<template lang="pug">
div(class='relative flex flex-1 flex-col')
  overlay(:visible='loading')
  div#viewer(class='hidden')
  p(
    class='prose mb-6 max-w-full text-sm text-text-light'
    v-html='$t("Wähle hier die Produkte aus die zu Projekt oder Anfrage hinzugefügt werden sollen. Die ausgewählten Produkte können innerhalb des Projekts nachträglich bearbeitet werden.")'
  )
  div(class='bleed')
    custom-data-table(v-model='selectedItems' :items='pconDataDTO' :config='config' type='multiselect' itemKey='obxUrl')
      template(#before='{ selected }')
        checkbox-icon(:value='selected')
      template(#image='{ value }')
        img(class='w-[150px] mix-blend-multiply' :src='value')
      template(#name='{ item }')
        div(class='text-sm text-text-light') {{ item.pConBrandName }}
        div
          b {{ item.name }}
        div
          a(:href='item.obxUrl' class='text-sm') {{ item.obxUrl }}
        text-auto-expand(:text='item.description' class='whitespace-pre-wrap text-sm' :maxNewLines='5')
      template(#priceNet='{ value }')
        div {{ formatPriceLocal(value) }}
sticky-container(enabled position='bottom' class='bleed py-4')
  div(class='unbleed') 
    action-bar
      template(#left)
        btn(tertiary @click='$emit("close")') {{ $t('close') }}
      btn(v-if='pconDataDTO.length' secondary :disabled='loading' icon='arrow-left' @click='reset()') {{ $t('back') }}
      btn(:disabled='loading || !pconDataDTO.length || !selectedItems.length' @click='addProducts') 
        span(v-html='$tc("Add {0} product(s)", selectedItems.length)')
</template>

<script setup lang="ts">
import { type ITableConfig } from '@/components/Table/CustomDataTable.vue'
import { usePconApplication } from '@/composables/pcon'
import { useInquiriesStore } from '@/pages/inquiries/store'
import { useProjectsStore } from '@/pages/projects/store'
import { useTranslation } from '@/plugins/translation'
import { type IPconDTO } from '@/types'
import { formatPrice } from '@/utilities'
import { useSessionStorage } from '@vueuse/core'
import { computed, ref } from 'vue'
import { onBeforeMount } from 'vue'

defineOptions({ inheritAttrs: false })
const props = defineProps({
  projectId: { type: String, required: true },
  inquiryId: String,
  tag: String,
  file: { type: File, required: true },
})
const emit = defineEmits(['close', 'reset'])
const { $t } = useTranslation()
const loading = ref(true)
const { articleManager, session, getBasketItemsForView, getAggregatedPconDTOForBasketItem, basketViewConfig, init } =
  usePconApplication()

const pconDataDTO = useSessionStorage<IPconDTO[]>('pcon-obx-import', [])
const store = useProjectsStore()
const inqStore = useInquiriesStore()
const addProducts = async () => {
  loading.value = true
  if (props.inquiryId) {
    await inqStore.addPConProducts(props.projectId, props.inquiryId, selectedPconDataDTO.value)
  } else {
    await store.addPConProducts(props.projectId, selectedPconDataDTO.value)
  }
  loading.value = false
  pconDataDTO.value = []
  emit('close')
}
const reset = async () => {
  pconDataDTO.value = []
  const currentItems = await session.basket.getAllItems()
  await session.basket.deleteItems(currentItems.map((item) => item.itemId))
  await articleManager.value?.synchronizeSession(false)
  emit('reset')
}
const selectedItems = ref<string[]>([])
const selectedPconDataDTO = computed(() =>
  pconDataDTO.value.filter((item) => selectedItems.value.includes(item.obxUrl!)),
)

const initFileReader = () => {
  const reader = new FileReader()
  reader.onload = async () => {
    loading.value = true
    await articleManager.value!.importObx(reader.result as ArrayBuffer)
    const basketItems = await getBasketItemsForView(basketViewConfig.value!.viewId)
    for (const item of basketItems) {
      const pconDTO = await getAggregatedPconDTOForBasketItem([item], basketViewConfig.value!.viewId!)
      pconDataDTO.value.push(pconDTO)
      loading.value = false
    }
    return false
  }
  reader.readAsArrayBuffer(props.file)
}
onBeforeMount(async () => {
  await init()
  initFileReader()
})

const config = computed<ITableConfig>(() => ({
  image: { label: '', sortable: false, class: 'image w-0' },
  name: { label: $t('Title'), sortable: false },
  priceNet: { label: $t('retailPriceNetString'), sortable: false },
}))
const formatPriceLocal = (priceAsString: string) => formatPrice(parseFloat(priceAsString))
</script>
